<template>
  <div class="Form-hsk">
    <div class="headTit pd40">
      <h3><span>HSK</span></h3>
    </div>
    <div class="cont">
      <div class="container">
        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="150px">
          <el-form-item label="Cert Name"
                        prop="cert_name">
            <el-input v-model="form.cert_name"></el-input>
          </el-form-item>
          <el-form-item label="Cn Name"
                        prop="cn_name">
            <el-input v-model="form.cn_name"></el-input>
          </el-form-item>
          <el-form-item label="Country"
                        prop="country">
            <el-input v-model="form.country"></el-input>
          </el-form-item>
          <el-form-item label="Gender"
                        prop="gender">
            <el-radio-group v-model="form.gender">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Birth Date"
                        prop="birth_date">
            <el-col :span="11">
              <el-date-picker type="date"
                              placeholder="select date"
                              v-model="form.birth_date"
                              style="width: 100%;"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="Language">
            <el-input v-model="form.language"></el-input>
          </el-form-item>
          <el-form-item label="Cert Type"
                        prop="cert_type">
            <el-select v-model="form.cert_type"
                       placeholder="select the ID type">
              <el-option label="passport"
                         value="passport"></el-option>
              <el-option label="ID Card"
                         value="ID Card"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Cert Code"
                        prop="cert_code">
            <el-input v-model="form.cert_code"></el-input>
          </el-form-item>
          <el-form-item label="Phone"
                        prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="E-Mail"
                        prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="Address"
                        prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item label="Post Code">
            <el-input v-model="form.post_code"></el-input>
          </el-form-item>
          <el-form-item label="How long has learned Chinese?">
            <el-input v-model="form.year"></el-input>
          </el-form-item>
          <el-form-item label="Subject"
                        prop="subject">
            <el-checkbox-group v-model="form.subject">
              <el-checkbox label="HSK（一级）"
                           name="type"></el-checkbox>
              <el-checkbox label="HSK（二级）"
                           name="type"></el-checkbox>
              <el-checkbox label="HSK（三级）"
                           name="type"></el-checkbox>
              <el-checkbox label="HSK（四级）"
                           name="type"></el-checkbox>
              <el-checkbox label="HSK（五级）"
                           name="type"></el-checkbox>
              <el-checkbox label="HSK（六级）"
                           name="type"></el-checkbox>
              <el-checkbox label="HSKK（初级）"
                           name="type"></el-checkbox>
              <el-checkbox label="HSKK（中级）"
                           name="type"></el-checkbox>
              <el-checkbox label="HSKK（高级）"
                           name="type"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="onSubmit('form')">Submit</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import { ElMessage } from 'element-plus'
import { inject } from "vue";

export default {
  emits: ['cloDialog'],
  name: 'Form-bm',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const dialogFlag = (inject("dialogFlag"));
    return {
      dialogFlag
    }
  },
  data () {
    return {
      form: {
        cert_name: '',
        cn_name: '',
        country: '',
        gender: '男',
        birth_date: '',
        language: '',
        cert_type: '',
        cert_code: '',
        phone: '',
        email: '',
        address: '',
        post_code: '',
        year: '',
        subject: [],
      },
      rules: {
        cert_name: [
          {
            required: true,
            message: 'Please input Id name',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 64,
            message: 'Length should be 3 to 64',
            trigger: 'blur',
          },
        ],
        cn_name: [
          {
            required: true,
            message: 'Please input Chinese Name',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 64,
            message: 'Length should be 3 to 5',
            trigger: 'blur',
          },
        ],
        country: [
          {
            required: true,
            message: 'Please select Nationality',
            trigger: 'change',
          },
        ],
        gender: [
          {
            required: true,
            trigger: 'change',
          },
        ],
        birth_date: [
          {
            required: true,
            message: 'Please select birthday',
            trigger: 'change',
          },
        ],
        cert_type: [
          {
            required: true,
            message: 'Please select ID type',
            trigger: 'change',
          },
        ],
        cert_code: [
          {
            required: true,
            message: 'Please input ID number',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Please input Phone number',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input your email',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please input correct email address',
            trigger: ['blur', 'change'],
          },
        ],
        address: [
          {
            required: true,
            message: 'Please input your address',
            trigger: 'blur',
          },
        ],
        subject: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one',
            trigger: 'change',
          },
        ]
      }
    }
  },
  methods: {
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axiosApi('message', {
            form_type: 2,
            ...this.form
          }, 'post').then(res => {
            if (res.data.status) {
              ElMessage.success('success')
              this.dialogFlag = false
            } else {
              ElMessage.error('error')
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.cont {
  .container {
    padding: 0 40px;
  }
}
</style>