<template>
  <div class="Kaoshi">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="Kaoshi pd40">
      <div class="container">
        <div class="headTit">
          <h3>Test <span>Preparation Center</span></h3>
        </div>
        <p class="_txt">ZHUXUE examination preparation center helps international students planning to enter vocational courses, basic courses, undergraduate courses or other courses to prepare and plan before the examination, pays attention to the elements of each examination that students may encounter, and trains students how to make breakthroughs and succeed in key examinations through best methods and practices.
        </p>
      </div>
      <div class="container">
        <el-row :gutter="24">
          <el-col :span="12">
            <div class="imgbox pd40">
              <img src="@/assets/ks01.jpg"
                   alt="">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="lis">
              <h3><span>HSK</span></h3>
              <p>
                Chinese Proficiency Test (HSK) is an international standardized test, which focuses on the ability of Chinese non first language candidates to communicate in Chinese in their life, study and work.
              </p>
              <p>
                The examination levels include HSK (Level 1), HSK (Level 2), HSK (Level 3), HSK (Level 4), HSK (Level 5) and HSK (Level 6).
              </p>
              <div class="bar">
                <router-link to="HSK"
                             class="more"><i class="iconfont">&#xe62a;</i>View More</router-link>
                <a @click="openFrom(1)"
                   class="baom"><i class="iconfont">&#xe62d;</i>Registration</a>
                <a href="http://zhuxue.net/down/HSK2020.pdf"
                   class="down"><i class="iconfont">&#xe625;</i>Download</a>
                <a href="javascript:;"
                   @click="onShowVioForm('HSK',9)"
                   class="emai"><i class="el-icon-message"></i>Message</a>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="background-color:#f9f9f9">
        <div class="container">
          <el-row :gutter="24">
            <el-col :span="12">
              <div class="lis">
                <h3><span>BCT</span></h3>
                <p>
                  Business Chinese test (BCT), as a standardized test of international Chinese ability, focuses on the ability of non Chinese first language candidates to communicate in Chinese in real business or general work situations.
                </p>
                <p>
                  BCT test is divided into written test and oral test, and they are independent of each other. The written examination includes BCT (A) and BCT (B).
                </p>
                <div class="bar">
                  <router-link to="BCT"
                               class="more"><i class="iconfont">&#xe62a;</i>View More</router-link>
                  <a @click="openFrom(2)"
                     class="baom"><i class="iconfont">&#xe62d;</i>Registration</a>
                  <a href="http://zhuxue.net/down/BCT2020.pdf"
                     class="down"><i class="iconfont">&#xe625;</i>Download</a>

                  <a href="javascript:;"
                     @click="onShowVioForm('BCT',10)"
                     class="emai"><i class="el-icon-message"></i>Message</a>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="imgbox pd40">
                <img src="@/assets/ks02.jpg"
                     alt="">
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="container">
        <el-row :gutter="24">
          <el-col :span="12">
            <div class="imgbox pd40">
              <img src="@/assets/ks03.jpg"
                   alt="">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="lis">
              <h3><span>MCT</span></h3>
              <p>
                Medical Chinese Proficiency Test (MCT) is a standardized language test to test the application level of medical Chinese. It is jointly developed by the headquarters of Confucius Institute, International Chinese Test and five key medical colleges in the United Nations. The medical Chinese Proficiency Test (MCT) is mainly for medical students, students who study medicine in Chinese outside China, and medical staff with Chinese as non-first language who use Chinese for clinical diagnosis and treatment inside and outside China to test their ability to communicate in Chinese with patients, medical staff and related personnel in the medical / medical scene.
              </p>
              <div class="bar">
                <router-link to="MCT"
                             class="more"><i class="iconfont">&#xe62a;</i>View More</router-link>

                <a href="javascript:;"
                   @click="onShowVioForm('MCT',11)"
                   class="emai"><i class="el-icon-message"></i>Message</a>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
    <div class="Guize pd40">
      <div class="container">
        <div class="headTit">
          <h3>Exam <span>rules</span></h3>
        </div>
        <div class="tit">
          Examination Registration and Payment
        </div>
        <div class="buzhou">
          <el-timeline>
            <el-timeline-item timestamp="Step 1"
                              placement="top">
              <el-card>
                <h4>Log in to the Chinese examination service network</h4>
                <p>website <a href="www.chinesetest.cn">www.chinesetest.cn</a></p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 2"
                              placement="top">
              <el-card>
                <h4>Registered user</h4>
                <p>To register as a user of the Chinese examination service network, remember your user name (email) and password. Registered users need to fill in email address, nationality, mother tongue, date of birth and other information.</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 3"
                              placement="top">
              <el-card>
                <h4>Examination registration</h4>
                <p>Select the test level, test form (paper and pen test and computer network test), test site and test time. Before signing up, you should first understand the test holding form of the selected test site</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 4"
                              placement="top">
              <el-card>

                <h4>Fill in personal information</h4>
                <p>Your name (consistent with the certificate), gender, date of birth, nationality, certificate information, etc. pay attention to the authenticity and integrity of the information</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 5"
                              placement="top">
              <el-card>
                <h4>Upload photos</h4>
                <p>Candidates who have been registered in the xG format of 100kB (two inches) in Chinese mainland should submit electronic photos. If they do not upload photos or upload photos, they will not be allowed to enter the examination room for examination.</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 6"
                              placement="top">
              <el-card>
                <h4>Confirm registration information</h4>
                <p>After verifying that the information is correct, select "confirm and submit"</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 7"
                              placement="top">
              <el-card>
                <h4>Payment of examination fee</h4>
                <p>Please pay the fee before the registration deadline, otherwise the registration is invalid. After the examination fee is paid successfully, you can't cancel the examination and open the test site with Yin payment. Candidates can pay online directly</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 8"
                              placement="top">
              <el-card>
                <h4>Get registration confirmation</h4>
                <p>Candidates who have paid the registration fee and filled in a valid email address will receive the registration confirmation 10 days before the examination. If they have not received it, please contact the registration examination center</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 9"
                              placement="top">
              <el-card>
                <h4>Get the printed registration card</h4>
                <p>Log in to the Chinese examination service network to print the admission ticket by yourself, or go to the registration examination center to get the admission ticket. You must bring your admission card and original valid ID card on the day of the examination</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="tit">
          Candidate photo requirements
        </div>
        <p>Photos shall be provided at all levels of the Chinese test according to the following requirements. Photos of hsk3-6, hskk, BCT and YCT oral examinations will also be used for the printing of performance reports.</p>
        <p>Photo style requirements:</p>
        <p>- My recent certificate photo (standard two inches) cannot be life photo or landscape photo</p>
        <p>- Do not wear hats, headscarves, hair bands and sunglasses (except those with special religious beliefs);</p>
        <p>- Black and white or color photos are available, preferably on a white background</p>
        <p>Candidates log in to the Chinese examination service network and upload electronic photos according to the website instructions, or submit the electronic photos to the teacher in charge of registration in the examination center. Please sign up for the teacher to assist in uploading.</p>
        <div class="tit">
          Registration Card
        </div>
        <p>The content of the Registration Card includes the examinee's name, nationality, gender, certificate type and number, examination subject, examination time, examination site name, examination place, examination room instructions, etc.</p>
        <p>After receiving the ticket, please carefully check whether the information on the card is accurate. The name, sex, date of birth and certificate number filled in the Registration Card must be consistent with the valid certificate, otherwise you are not allowed to take the exam.</p>
        <p>If the information on the Registration Card is incorrect, please contact the test site for change, otherwise the test will be invalid.</p>
        <p>The Registration Card is an important proof for candidates to enter the examination. Candidates who do not carry the Registration Card are not allowed to enter the examination. After the examination, candidates need to continue to save their Registration Cards and provide them when receiving the score report. You also need to provide your Registration Card number when you query your grades online and apply for additional score reports and other value-added services.</p>
        <div class="tit">
          Change test date
        </div>
        <p>After completing the registration and payment, candidates can apply to change the examination date within the specified time, but they can't cancel the registration. The registered examination place and examination level can't be changed. The change of examination date is only accepted once for a single registration.</p>
        <p>Candidates need to log in to the "personal center" through the Chinese examination service network (www.chinesetest. CN) to apply for changing the examination date. The changeable examination date is any examination of the same level held by the registration examination center in the current year.</p>
        <p>The time limit and cost for changing the examination date are as follows:</p>
        <p>• 27 days before the test (including the 27th day): candidates can change it free of charge.</p>
        <p>• from 27 days before the examination to 10 days before the examination (including the 10th day): candidates applying for change during this period must pay a change handling fee of 30% of the examination fee in advance.</p>
        <p>• within 10 days before the examination (excluding the 10th day): no change application will be accepted.</p>
        <p>Note: the above rules apply to both paper and pen examinations and online examinations.</p>
        <div class="tit">
          Test regulations
        </div>
        <p class="b">1. Test items</p>
        <p>On the day of examination, candidates must bring:</p>
        <p>- Registration Card</p>
        <p>- True and valid personal ID card with photos consistent with the registration (the copy is invalid)</p>
        <p>- You must bring 2B pencils (more than two) and erasers to take the paper and pencil examination</p>
        <p>Other personal belongings of the examinee must be placed in the storage place designated by the examiner and cannot be taken with him. Please do not bring valuables to the examination room, otherwise you will not be responsible for loss.</p>
        <p>Candidates who fail to provide qualified certificates and admission tickets will not be able to take the exam. The examination fee is non refundable.</p>
        <p class="b">2. Admission time</p>
        <p>Candidates should arrive at the examination room half an hour before the examination to avoid being late. After admission until the end of the listening test, candidates are not allowed to enter and leave the examination room.</p>
        <p>Paper and pencil test: before the hearing test, late candidates can enter the examination room to take the test; After the listening test, late candidates must wait until the listening test is over before entering the examination room to take the reading test. The missed time will not be made up; After the reading test begins, late candidates are not allowed to enter the examination room to take the test.</p>
        <p>Oral test: before recording, late candidates can enter the examination room to take the test, and the missed time will not be made up; After the recording begins, late candidates are not allowed to enter the examination room for the examination.</p>
        <p class="b">4. Test regulations</p>
        <p>(1) Candidates must sit in the designated seats. Candidates have no right to choose seats.</p>
        <p>(2) Candidates must follow the examiner's instructions, abide by the examination rules, and complete all examinations independently.</p>
        <p>(3) There is no break time during the examination. If there are special reasons, the candidate needs to leave halfway, he must obtain the consent of the examiner. Before leaving the examination room, he shall hand over the admission card and ID card to the invigilator. After the candidate returns to the examination room, the invigilator shall return the candidate's admission card and ID card.</p>
        <p>(4) In the oral test, candidates are not allowed to leave the examination room temporarily. If they want to leave, they can only quit the test.</p>
        <p class="b">5. Emergency handling</p>
        <p>(1) Due to the management error of the examination room, candidates are treated unfairly, such as insufficient examination time, damaged examination papers, damaged examination equipment, etc., resulting in candidates being unable to complete the examination. CETC international will arrange candidates to take the examination again free of charge as soon as possible, without any indirect loss compensation, and the examination place cannot be changed.</p>
        <p>(2) If the test cannot be carried out due to force majeure, such as natural disasters and accidents, sinokao international will arrange to try again as soon as possible, or return the test fee in full without any indirect loss compensation.</p>
        <p class="b">6. Absent of candidates</p>
        <p>If the examinee fails to participate in the examination on the day of the examination due to reasons other than the examination organizer, the examination fee will not be refunded.</p>
        <p class="b">7. Violation and punishment</p>
        <p>Candidates are not allowed to open the test paper in advance, cross regional questions, tear, replace and copy the content of the test paper, and take the test paper and answers out of the examination room. If candidates cheat, substitute, plagiarize, entrain and do not obey the instructions of the examiner, the test results will be cancelled.</p>
        <div class="tit">
          Test score
        </div>
        <p>1. Score query</p>
        <p>Two weeks after the end of the online examination and one month after the end of the paper and pen examination, candidates can log in to the Chinese examination service network (www.chinesetest. CN) and enter the admission ticket number and name to query the results.</p>
        <p>2. Result report</p>
        <p>Chinese mainland candidates, please fill in the designated address online after the examination results are published, apply for diagnostic analysis and report service (result report application). The result reports of candidates from other regions will be sent to the test center within 1-2 months after the test. Candidates can get them at the test center with their registration card and ID card.</p>
        <p>3. Validity period of result report</p>
        <p>The validity period of Chinese test results is two years (from the date of the test).</p>
        <div class="tit">
          Additional service after test
        </div>
        <p>1. Additional result report posting service</p>
        <p>In order to facilitate candidates to use the Chinese proficiency test score report as a proof of Chinese ability such as school application, graduation certificate, application and promotion, candidates can apply for additional score reports through the Chinese examination service network (www.chinesetest. cn). Please log in to the Chinese language examination service website (www.chinesetest. cn) for application methods and charging standards.</p>
        <p>2. Result report modification service</p>
        <p>The score report of Chinese proficiency test is an important proof of candidates' Chinese ability. Some candidates fail to carefully check the information as required when registering online or at the test site. After registration, they fail to carefully check the registration confirmation letter and admission ticket, resulting in the inconsistency between the personal information on the score report and the real situation. In order not to affect the normal use of the score report, candidates can apply for the score report modification service through the Chinese examination service network (www.chinesetest. cn).</p>
        <p>Result report modification service application conditions:</p>
        <p>(1) The information applied for modification is limited to the following situations:</p>
        <p>• misspelled name</p>
        <p>• wrong nationality information</p>
        <p>• gender information error</p>
        <p>(2) Candidates need to provide relevant supporting documents, such as passport and ID card.</p>
        <p>(3) The application for revision of the performance report must be submitted within the validity period of the performance report (within two years after the end of the examination).</p>
        <p>3. Result review services</p>
        <p>In order to meet the needs of candidates and answer examination questions, candidates can log in to the personal center of Chinese examination service network to apply for score reconsideration service from now on. The time limit for applying for reconsideration service shall be within 30 days after the announcement of the examination results. Once the application for performance reconsideration is submitted successfully, the reconsideration items cannot be cancelled or modified. During the review period, candidates' scores will be frozen and shall not be used for academic, work and scholarship applications. During this period, candidates cannot query their scores online or apply for additional score reports.</p>
      </div>
    </div>
    <el-dialog v-model="dialogVisible"
               width="800px"
               :lock-scroll="false"
               :before-close="handleClose">
      <Baoming1 v-if="isForm1 == 1"></Baoming1>
      <Baoming2 v-else></Baoming2>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">Close</el-button>
        </span>
      </template>
    </el-dialog>

    <ComForm ref='vioForm'
             :typeId='fotmTypeId'
             :formTitle="formTitle"></ComForm>
  </div>
</template>

<script>
import Baoming1 from '@/components/Form-hsk.vue'
import Baoming2 from '@/components/Form-bct.vue'
import { provide, ref } from "vue";
import ComForm from "@/components/ComForm.vue";

export default {

  name: 'Kaoshi',
  components: {
    Baoming1,
    Baoming2,
    ComForm

  },
  data () {
    return {
      news: [
        {
          title: '新闻标题',
          cont: '新闻内容',
          img: require('@/assets/news.png'),
          date: '2020-02-20',
        },
        {
          title: '新闻标题',
          cont: '新闻内容',
          img: require('@/assets/news.png'),
          date: '2020-02-20',
        },
        {
          title: '新闻标题',
          cont: '新闻内容',
          img: require('@/assets/news.png'),
          date: '2020-02-20',
        },
        {
          title: '新闻标题',
          cont: '新闻内容',
          img: require('@/assets/news.png'),
          date: '2020-02-20',
        },
        {
          title: '新闻标题',
          cont: '新闻内容',
          img: require('@/assets/news.png'),
          date: '2020-02-20',
        },
      ],
      isForm1: 1
    }
  },
  setup () {
    const dialogVisible = ref(false)
    const formTitle = ref(false)
    const fotmTypeId = ref(false)
    provide("dialogFlag", dialogVisible);
    const vioForm = ref()
    const onShowVioForm = (val, id) => {
      formTitle.value = val
      fotmTypeId.value = id
      vioForm.value.onShowVio()
    }

    return {
      dialogVisible,
      vioForm,
      onShowVioForm,
      formTitle,
      fotmTypeId
    }
  },
  methods: {
    handleClose (done) {
      this.$confirm('Determine the closing window?')
        .then(() => {
          done()
        })
        .catch(() => { })
    },
    openFrom (num) {
      this.isForm1 = num
      this.dialogVisible = true
    }

  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner4.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
._txt {
  font-size: 14px;
  text-indent: 2em;
  line-height: 2;
}
.Kaoshi {
  .lis {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      font-size: 20px;
      span {
        color: #0c3879;
        font-size: 50px;
        font-weight: bold;
        margin-right: 5px;
      }
    }
    p {
      font-size: 14px;
      color: #666;
      line-height: 2;
      text-indent: 2em;
      margin-top: 10px;
    }
    .bar {
      display: flex;
      margin-top: 20px;
      a {
        display: block;
        padding: 10px 20px;
        margin-right: 10px;
        border-radius: 5px;
        font-size: 14px;
        i {
          margin-right: 5px;
        }
        &.more {
          background-color: #1173cc;
          color: #fff;
        }
        &.baom {
          background-color: #008dd7;
          color: #fff;
        }
        &.down {
          background-color: #bc3053;
          color: #fff;
        }
        &.emai {
          background-color: #d8a31a;
          color: #fff;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
.Guize {
  p {
    font-size: 16px;
    line-height: 2;
    text-indent: 2em;
    margin-bottom: 10px;
    &.b {
      text-indent: 0;
      font-weight: bold;
      margin-top: 15px;
    }
  }
  .buzhou {
    h4 {
      margin: 0;
      margin-bottom: 10px;
    }
    a {
      color: #0c3879;
    }
    p {
      text-indent: 0;
      font-size: 14px;
      color: #454545;
    }
  }
}
</style>